<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0">
        <v-card-title>
          <a
            href="#"
            :class="{
              'active-request-type-link': requestTypeToShow == 'essential'
            }"
            @click.prevent="requestTypeToShow = 'essential'"
          >
            Requests ({{ requestsCount }})</a
          >
          <a
            href="#"
            class="ml-4"
            :class="{
              'active-request-type-link':
                requestTypeToShow == 'Request For Proposal'
            }"
            @click.prevent="requestTypeToShow = 'Request For Proposal'"
            >Request For Proposal ({{ requestForProposalCount }})</a
          >
          <a
            href="#"
            class="ml-4"
            :class="{
              'active-request-type-link': requestTypeToShow == 'Wildlife Damage'
            }"
            @click.prevent="requestTypeToShow = 'Wildlife Damage'"
            >Wildlife Damage ({{ wildlifeDamageCount }})</a
          >
          <a
            href="#"
            class="ml-4"
            :class="{
              'active-request-type-link': requestTypeToShow == 'Mulch Request'
            }"
            @click.prevent="requestTypeToShow = 'Mulch Request'"
            >Mulch Request ({{ mulchRequestCount }})</a
          >
        </v-card-title>
        <v-card-text>
          <!-- Start Requests section -->
          <v-row>
            <v-col>
              <!-- <v-row>
                <v-col cols="12" md="2">
                  <v-select
                    v-model="viewByPriority"
                    :items="viewByPriorityItems"
                    item-text="name"
                    item-value="value"
                    label="Choose Priority..."
                  ></v-select>
                </v-col>
              </v-row> -->

              <v-tabs
                v-model="tab"
                background-color="transparent"
                centered
                grow
              >
                <v-tab href="#tab-1"> New ({{ newCount }}) </v-tab>
                <v-tab href="#tab-2"> Due This Week ({{ dueCount }}) </v-tab>
                <v-tab href="#tab-3"> All Open ({{ openCount }}) </v-tab>
                <v-tab href="#tab-4"> In Process ({{ inProcessCount }}) </v-tab>
                <v-tab href="#tab-5"> On Hold ({{ onHoldCount }}) </v-tab>
                <v-tab href="#tab-6"> Closed ({{ closedCount }}) </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="i in 6" :key="i" :value="'tab-' + i">
                  <v-container fluid>
                    <v-row>
                      <v-col cols="2">
                        <!-- <v-btn
                          class="orange-bg white--text"
                          @click="getExportFile"
                        >
                          Export
                        </v-btn> -->
                        <v-btn
                          :disabled="selectedRequests.length == 0"
                          class="orange-bg white--text mt-4"
                          @click="
                            printRequests(selectedRequests.map(i => i.id))
                          "
                          small
                        >
                          Print Selected ({{ selectedRequests.length }})
                        </v-btn>
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="search"
                          label="Search"
                          prepend-inner-icon="mdi-magnify"
                          variant="outlined"
                          hide-details
                          single-line
                          class="mb-2"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-data-table
                      :headers="headers"
                      :items="records"
                      :search="search"
                      item-key="id"
                      :loading="isLoading"
                      :items-per-page="50"
                      :footer-props="{
                        'items-per-page-options': [50, 100, 150, 200, -1]
                      }"
                      :options.sync="options"
                      loading-text="Loading... Please wait"
                      class="elevation-1 request-table"
                      select-strategy="all"
                      show-select
                      item-value="id"
                      v-model="selectedRequests"
                    >
                      <template #[`item.requestTypeName`]="{ item }">
                        <router-link
                          :to="
                            `/customers/${item.communityId}/requests/${item.id}/update`
                          "
                          class="blue-color"
                        >
                          {{ item.requestType.name }}
                        </router-link>
                      </template>
                      <template #[`item.streetLocation`]="{ item }">
                        <span>{{ item.location }} </span>
                      </template>
                      <template #[`item.comments`]="{ item }">
                        <span>{{ item.comments }} </span>
                      </template>
                      <template #[`item.dateCreated`]="{ item }">
                        <span
                          :style="
                            new Date(item.targetCompletionDate) < new Date()
                              ? 'color: red; font-weight: bold;'
                              : ''
                          "
                          >{{ formatDate(item.dateCreated) }}
                        </span>
                      </template>
                      <!-- <template #[`item.priorityId`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              :color="
                                item.priority.name === 'Normal'
                                  ? 'green'
                                  : item.priority.name === 'High'
                                  ? 'red'
                                  : 'blue'
                              "
                            >
                              fas fa-circle
                            </v-icon>
                          </template>
                          <span>{{ item.priority.name }}</span>
                        </v-tooltip>
                      </template> -->
                      <template #[`item.actions`]="{ item }">
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          @click.stop="item.deleteDialog = true"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>

                        <v-dialog
                          v-model="item.deleteDialog"
                          persistent
                          max-width="290"
                        >
                          <v-card>
                            <v-card-title class="headline">
                              Delete Request
                            </v-card-title>
                            <v-card-text
                              >Are you sure you want to delete request:
                              <p>{{ item.title }}</p></v-card-text
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="green darken-1"
                                text
                                @click="item.deleteDialog = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="green darken-1"
                                text
                                @click="deleteRequest(item)"
                              >
                                Delete
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
          <!-- End Requests section -->
        </v-card-text>
      </v-card>
      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import {
  GET_QUERY,
  GET_PAGED,
  DELETE,
  API_CUSTOMERS,
  API_SERVICE_REQUESTS,
  POST_DATA
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import requestsPrinter from "@/core/helpers/printRequests";
import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
import moment from "moment";
import Mgr from "@/core/services/security.service";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    mgr: new Mgr(),
    userInfo: null,
    communityInfo: {},
    options: {
      page: 1,
      itemsPerPage: 50,
      sortBy: ["dateCreated"],
      sortDesc: [true]
    },
    search: "",
    headers: [
      {
        text: "Date Created",
        align: "start",
        value: "dateCreated",
        class: "orange-bg"
      },
      { text: "Issue #", value: "id", class: "orange-bg" },
      {
        text: "Service Type",
        value: "requestTypeName",
        class: "orange-bg"
      },
      {
        text: "Property Address",
        value: "streetLocation",
        class: "orange-bg"
      },
      {
        text: "Description",
        value: "description",
        class: "orange-bg"
      },
      { text: "Assigned To", value: "assignedToFullName", class: "orange-bg" },
      // { text: "Priority", value: "priorityId", class: "orange-bg" },
      {
        text: "Comments",
        value: "comments",
        class: "orange-bg",
        sortable: false
      },
      { text: "Actions", value: "actions", class: "orange-bg", sortable: false }
    ],
    records: [],
    selectedRequests: [],
    totalRecords: null,

    // viewByPriority: "all",
    // viewByPriorityItems: [
    //   { name: "View All priorities", value: "all" },
    //   { name: "Low", value: "Low" },
    //   { name: "Normal", value: "Normal" },
    //   { name: "High", value: "High" }
    // ],
    tab: null,
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    newCount: 0,
    openCount: 0,
    dueCount: 0,
    inProcessCount: 0,
    onHoldCount: 0,
    closedCount: 0,
    // proposals = Request For Proposal
    // wildlife = Wildlife Damage
    // mulch = Mulch Request
    requestTypeToShow: "essential", // possible types here: essential, Request For Proposal, Wildlife Damage, Mulch Request
    requestsCount: 0,
    requestForProposalCount: 0,
    wildlifeDamageCount: 0,
    mulchRequestCount: 0
  }),

  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    // options: async function() {
    //   await this.getRequests();
    // },
    // "options.sortBy": async function() {
    //   await this.getRequests();
    // },
    // viewByPriority: async function() {
    //   await this.getRequests();
    // },
    tab: async function() {
      await this.getRequests();
    },
    requestTypeToShow: async function() {
      await this.getRequests();
    }
  },
  async mounted() {
    await this.getRequests();

    // await this.printRequests([773, 768, 781]);
  },
  async created() {
    // let info = await this.mgr.getUser();
    // console.log("user info", info);
    // this.userInfo = await this.mgr.getUser();
    await this.getComunityInfo();
  },
  methods: {
    deleteRequest(item) {
      let self = this;
      if (!item) return;

      this.$store
        .dispatch(DELETE, {
          listName: `${API_SERVICE_REQUESTS}`,
          id: item.id
        })
        .then(function() {
          item.deleteDialog = false;

          self.getRequests();
        });
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      if (this.loading) return;
      this.loading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);

            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        { title: "Requests" }
      ];
    },

    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        { title: "Service Requests" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    // getRecords() {
    //   return dataModels.serviceRequestItems;
    // },
    async getRequests() {
      if (this.isLoading) return;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_PAGED, {
          listName: `${API_SERVICE_REQUESTS}/Community/${this.customerId}`,
          params: { mediumFormat: true }
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            //const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const { sortBy, sortDesc } = this.options;
            let items = response.data.$values;

            // request type
            // essential = all except Wildlife Damage, Mulch Request, Request For Proposal
            // proposals = Request For Proposal
            // wildlife = Wildlife Damage
            // mulch = Mulch Request

            let requestsItems = [];
            let requestForProposalItems = [];
            let wildlifeDamageItems = [];
            let mulchRequestItems = [];
            let typesNotToInclude = [
              "Wildlife Damage",
              "Mulch Request",
              "Request For Proposal"
            ];
            requestsItems = items.filter(
              el => !typesNotToInclude.includes(el.requestType?.name)
            );
            this.requestsCount = requestsItems.length;

            requestForProposalItems = items.filter(
              el => el.requestType?.name == "Request For Proposal"
            );
            this.requestForProposalCount = requestForProposalItems.length;

            wildlifeDamageItems = items.filter(
              el => el.requestType?.name == "Wildlife Damage"
            );
            this.wildlifeDamageCount = wildlifeDamageItems.length;

            mulchRequestItems = items.filter(
              el => el.requestType?.name == "Mulch Request"
            );
            this.mulchRequestCount = mulchRequestItems.length;

            if (this.requestTypeToShow == "essential") {
              items = requestsItems;
            } else if (this.requestTypeToShow == "Request For Proposal") {
              items = requestForProposalItems;
            } else if (this.requestTypeToShow == "Wildlife Damage") {
              items = wildlifeDamageItems;
            } else if (this.requestTypeToShow == "Mulch Request") {
              items = mulchRequestItems;
            }

            let newItems = [];
            let dueItems = [];
            let openItems = [];
            let inProcessItems = [];
            let onHoldItems = [];
            let closedItems = [];

            let endWeekDate = moment
              .utc()
              .endOf("isoWeek")
              .toDate();

            dueItems = items.filter(
              el =>
                moment.utc(el.targetCompletionDate).toDate() < endWeekDate &&
                !el.isCompleted &&
                !el.isNew &&
                !el.isOnHold
            );
            this.dueCount = dueItems.length;
            closedItems = items.filter(el => el.isCompleted);
            this.closedCount = closedItems.length;
            inProcessItems = items.filter(el => el.isInProcess);
            this.inProcessCount = inProcessItems.length;
            onHoldItems = items.filter(el => el.isOnHold);
            this.onHoldCount = onHoldItems.length;
            newItems = items.filter(el => el.isNew);
            this.newCount = newItems.length;
            openItems = items.filter(el => el.isOpen);
            this.openCount = openItems.length;

            if (this.tab === "tab-2") {
              // due this week
              items = dueItems;
            } else if (this.tab === "tab-6") {
              // closed
              items = closedItems;
            } else if (this.tab === "tab-5") {
              // on hold
              items = onHoldItems;
            } else if (this.tab === "tab-4") {
              // in process
              items = inProcessItems;
            } else if (this.tab === "tab-1") {
              // new
              items = newItems;
            } else {
              // all open
              items = openItems;
            }

            items.forEach(function(element) {
              element.deleteDialog = false;
              element.assignedToFullName = element.assignedTo?.fullName;
              // element.priorityId = element.priority.id;
              element.requestTypeName = element.requestType?.name;

              if (element.location)
                element.streetLocation = element.location
                  .replace(/\b\d+/i, "")
                  .trimStart();
              else element.streetLocation = "";
            });

            const total = items.length;

            if (sortBy.length === 1 && sortDesc.length === 1) {
              items = items.sort((a, b) => {
                const sortA = a[sortBy[0]];
                const sortB = b[sortBy[0]];

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1;
                  if (sortA > sortB) return -1;
                  return 0;
                } else {
                  if (sortA < sortB) return -1;
                  if (sortA > sortB) return 1;
                  return 0;
                }
              });
            }
            this.records = items;
            this.totalRecords = total;
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
          this.isLoading = false;
        });
    },
    getAssignedTo(assignedTo) {
      return assignedTo?.fullName;
    },
    getCreatedBy(createdBy) {
      return createdBy?.fullName;
    },
    async printRequests(requestIds) {
      let url = `${API_SERVICE_REQUESTS}/GetByIds`;

      let requestsResponse = await this.$store.dispatch(POST_DATA, {
        listName: url,
        data: requestIds
      });

      let requestsToPrint = requestsResponse.data.$values;
      await requestsPrinter.printRequests(requestsToPrint);

      // // let requestIds = this.selectedRequests.map(i => {
      // //   return i.id;
      // // });
      // let pdfMake = require("pdfmake/build/pdfmake.js");
      // if (pdfMake.vfs == undefined) {
      //   var pdfFonts = require("pdfmake/build/vfs_fonts.js");
      //   pdfMake.vfs = pdfFonts.pdfMake.vfs;
      // }

      // let self = this;
      // let url = `${API_SERVICE_REQUESTS}/GetByIds`;

      // let requestsResponse = await self.$store.dispatch(POST_DATA, {
      //   listName: url,
      //   data: requestIds
      // });

      // let requestsToPrint = requestsResponse.data.$values;

      // pdfMake.fonts = {
      //   Fontello: {
      //     normal: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
      //     bold: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
      //     italics: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
      //     bolditalics:
      //       self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf"
      //   },

      //   //download default Roboto font from cdnjs.com
      //   Roboto: {
      //     normal:
      //       "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
      //     bold:
      //       "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
      //     italics:
      //       "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
      //     bolditalics:
      //       "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf"
      //   }
      // };

      // let reportBody = [];
      // let itemCount = 0;

      // requestsToPrint.forEach(function(item) {
      //   itemCount += 1;
      //   if (itemCount > 1) {
      //     reportBody.push({
      //       text: "",
      //       fontSize: 14,
      //       bold: false,
      //       pageBreak: "before",
      //       margin: [0, 0, 0, 0]
      //     });
      //   }
      //   let row = [];

      //   let mainTableBody = [];
      //   row.push({
      //     text: "#" + item.id,
      //     fontSize: 18,
      //     bold: true,
      //     pageBreak: "",
      //     margin: [0, 0, 0, 8]
      //   });

      //   mainTableBody.push(row);

      //   let requestDetails = [];
      //   requestDetails.push({
      //     text: item.requestType?.name,
      //     fontSize: 14,
      //     bold: true,
      //     margin: [0, 0, 0, 8]
      //   });

      //   requestDetails.push({
      //     margin: [8, 0, 0, 8],
      //     table: {
      //       body: [
      //         [
      //           { text: "Community:", bold: true, fontSize: 10 },
      //           { text: item.communityName, bold: false, fontSize: 10 },
      //           { text: "Location:", bold: true, fontSize: 10 },
      //           { text: item.location, bold: false, fontSize: 10 }
      //         ],
      //         [
      //           { text: "Opened By:", bold: true, fontSize: 10 },
      //           {
      //             text: self.getCreatedBy(item.createdBy),
      //             bold: false,
      //             fontSize: 10
      //           },
      //           { text: "Opened:", bold: true, fontSize: 10 },
      //           {
      //             text: self.formatDate(item.created),
      //             bold: false,
      //             fontSize: 10
      //           }
      //         ],
      //         [
      //           { text: "Assigned To:", bold: true, fontSize: 10 },
      //           {
      //             text: self.getAssignedTo(item.assignedTo),
      //             bold: false,
      //             fontSize: 10
      //           },
      //           { text: "Due Date:", bold: true, fontSize: 10 },
      //           {
      //             text: self.formatDate(item.targetCompletionDate),
      //             bold: false,
      //             fontSize: 10
      //           }
      //         ],
      //         [
      //           { text: "Contact Name:", bold: true, fontSize: 10 },
      //           { text: item.contactName, bold: false, fontSize: 10 },
      //           { text: "Contact Phone:", bold: true, fontSize: 10 },
      //           { text: item.contactPhone, bold: false, fontSize: 10 }
      //         ],
      //         [
      //           { text: "Contact Email:", bold: true, fontSize: 10 },
      //           { text: item.contactEmail, bold: false, fontSize: 10 },
      //           "",
      //           ""
      //         ]
      //       ]
      //     },
      //     layout: "noBorders"
      //   });

      //   requestDetails.push({
      //     text: "Description:",
      //     bold: true,
      //     fontSize: 10,
      //     margin: [8, 0, 0, 0]
      //   });
      //   requestDetails.push({
      //     text: item.description,
      //     bold: false,
      //     fontSize: 10,
      //     margin: [16, 0, 0, 8]
      //   });
      //   if (item.comments.$values.length > 0) {
      //     requestDetails.push({
      //       text: "Comments",
      //       fontSize: 10,
      //       bold: true,
      //       margin: [8, 0, 0, 8]
      //     });

      //     item.comments.$values.forEach(function(comment) {
      //       requestDetails.push({
      //         text:
      //           self.getCreatedBy(comment.createdBy) +
      //           " on " +
      //           self.formatDate(comment.created),
      //         bold: true,
      //         fontSize: 10,
      //         margin: [16, 0, 0, 0]
      //       });
      //       requestDetails.push({
      //         text: comment.description,
      //         bold: false,
      //         fontSize: 10,
      //         margin: [16, 0, 0, 8]
      //       });
      //     });
      //   }

      //   row = [];
      //   row.push(requestDetails);

      //   mainTableBody.push(row);

      //   reportBody.push({
      //     layout: "noBorders",
      //     table: {
      //       headerRows: 1,
      //       body: mainTableBody
      //     }
      //   });
      // });

      // let docDefinition = {
      //   images: {
      //     ynLogo: self.baseUrl + "/media/logos/yardnique-logo.png"
      //   },
      //   pageSize: "LETTER",
      //   pageMargins: [20, 20, 20, 20],
      //   content: reportBody,
      //   defaultStyle: {
      //     fontSize: 12,
      //     bold: false
      //   },
      //   styles: {
      //     heading: {
      //       color: "#E06521",
      //       bold: true,
      //       margin: [0, 0, 0, 5]
      //     },
      //     icon: { font: "Fontello" },

      //     mt5: { margin: [0, 5, 0, 0] },
      //     mt10: { margin: [0, 10, 0, 0] },
      //     mb5: { margin: [0, 0, 0, 5] },
      //     mb10: { margin: [0, 0, 0, 10] },
      //     my5: { margin: [0, 5, 0, 5] },
      //     my10: { margin: [0, 10, 0, 10] }
      //   }
      // };

      // pdfMake.createPdf(docDefinition).open();
    }
  },
  computed: {
    sort() {
      if (
        this.options.sortBy &&
        this.options.sortBy[0] &&
        this.options.sortBy[0].length > 0
      ) {
        if (this.options.sortBy[0] === "fullName") {
          return `firstName ${this.options.sortDesc[0] ? "desc" : "asc"}`;
        } else {
          return `${this.options.sortBy[0]} ${
            this.options.sortDesc[0] ? "desc" : "asc"
          }`;
        }
      }
      return "";
    }
  }
};
</script>
<style lang="scss" scoped>
.v-data-table.request-table table thead.v-data-table-header tr {
  background-color: #df6526 !important;
}
.active-request-type-link {
  color: #df6526 !important;
}
</style>
